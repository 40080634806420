import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { isDesktop } from './helpers';

gsap.registerPlugin(ScrollTrigger);

const els = gsap.utils.toArray('.animate');

els.forEach( (el) => {
  animate(el);
});

function animate(el) {
  if (isMobileDisabled(el)) return false;

  if (el.classList.contains('fade-in-right')) fadeInRight(el, true);
  if (el.classList.contains('fade-in-left')) fadeInLeft(el, true);
  if (el.classList.contains('fade-in-top')) fadeInTop(el, true);
  if (el.classList.contains('fade-in')) fadeIn(el, true);
}

function fadeInLeft(el, animate) {
  let baseDelay = isDesktop() ? 0.2 : 0;
  let baseStart = isDesktop() ? '150' : '25';
  let delay = baseDelay + parseFloat(el.dataset.animateDelay);
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: baseStart + ' bottom',
    }
  })
  tl.from(el, {
    opacity: 0,
    x: -60,
    delay: delay,
    ease: "power3"
  })
  tl.to(el, {
    ease: "power3",
    opacity: 1,
    x: 0,
  })
}

function fadeInRight(el, animate) {
  let baseDelay = isDesktop() ? 0.2 : 0;
  let baseStart = isDesktop() ? '150' : '25';
  let delay = baseDelay + parseFloat(el.dataset.animateDelay);
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: baseStart + ' bottom',
    }
  })
  tl.from(el, {
    opacity: 0,
    x: 60,
    delay: delay,
    ease: "power3"
  })
  tl.to(el, {
    ease: "power3",
    opacity: 1,
    x: 0,
  })
}

function fadeInTop(el, animate) {
  let baseDelay = isDesktop() ? 0.2 : 0;
  let baseStart = isDesktop() ? '150' : '25';
  let delay = baseDelay + parseFloat(el.dataset.animateDelay);
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: baseStart + ' bottom',
    }
  })
  tl.from(el, {
    opacity: 0,
    y: 60,
    delay: delay,
    ease: "power3"
  })
  tl.to(el, {
    ease: "power3",
    opacity: 1,
    y: 0,
  })
}

function fadeIn(el, animate) {
  let baseDelay = isDesktop() ? 0.2 : 0;
  let baseStart = isDesktop() ? '150' : '25';
  let delay = baseDelay + parseFloat(el.dataset.animateDelay);
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: baseStart + ' bottom',
    }
  })
  tl.from(el, {
    opacity: 0,
    delay: delay,
    ease: "power3"
  })
  tl.to(el, {
    opacity: 1,
    ease: "power3"
  })
}

function isMobileDisabled(el) {
  if (isDesktop()) return false;
  if (el.dataset.disableMobile === true || el.dataset.disableMobile === 'true') {
    showInstant(el);
    return true;
  } else {
    return false;
  }
}

function showInstant(el) {
  el.style.opacity = 1;
}