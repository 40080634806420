import Swiper from 'swiper';

const swiper = new Swiper('.trusted-by-slider', {
  slidesPerView: 3,
  spaceBetween: 30,
  slidesPerGroup: 3,
  loop: true,
  loopFillGroupWithBlank: true,
  autoplay: {
    delay: 2500,
  },
});


