const html = document.querySelector('html');
const body = document.querySelector('body');
const mainHeader = document.querySelector('.header__main');
const hamburger = document.querySelector('.hamburger');
const navigation = document.querySelector('#navigation');
const blockContent = document.querySelector('.block_content');
const mainEl = document.querySelector('.main');
const navEl = document.querySelector('.navsection');

document.addEventListener( 'scroll', (e) => {
  if (window.scrollY > 40) {
    if (hamburger) {
      hamburger.style.top = '33px'
    }
    mainHeader.style.top = '-50px';
  } else {
    if (hamburger) {
      hamburger.style.top = '80px'
    }
    mainHeader.style.top = '0px';
  }

  if (body.classList.contains('tax')) {
    const logocolor = document.querySelector('#logocolor').value;
    if (window.scrollY > 750) {
      document.querySelector('.cls-2').style.fill = logocolor;
    } else {
      document.querySelector('.cls-2').style.fill = '#000';
    }
  }


  if (blockContent) {
    Array.from(blockContent.children).forEach((el) => {
        if ((el.getBoundingClientRect().y > 0 && el.getBoundingClientRect().y < 100) 
        || ( el.getBoundingClientRect().y + el.offsetHeight > 95 && el.getBoundingClientRect().y + el.offsetHeight < 200)) {
        if (el.dataset.blockcolor === 'light') {
          if (!document.querySelector('.header__logo__outline').classList.contains('dark')) {
            document.querySelector('.header__logo__outline').classList.add('dark');
          }
          if (document.querySelector('.header__logo__outline').classList.contains('light')) {
            document.querySelector('.header__logo__outline').classList.remove('light');
          }
          if (!navEl.classList.contains('dark')) {
            navEl.classList.add('dark');
          }
          if (navEl.classList.contains('light')) {
            navEl.classList.remove('light');
          }
        } else if (el.dataset.blockcolor === 'dark') {
          if (!document.querySelector('.header__logo__outline').classList.contains('light')) {
            document.querySelector('.header__logo__outline').classList.add('light');
          }
          if (document.querySelector('.header__logo__outline').classList.contains('dark')) {
            document.querySelector('.header__logo__outline').classList.remove('dark');
          }
          if (!navEl.classList.contains('light')) {
            navEl.classList.add('light');
          }
          if (navEl.classList.contains('dark')) {
            navEl.classList.remove('dark');
          }
        }
      } else if (mainEl.getBoundingClientRect().y + mainEl.offsetHeight > 100 && mainEl.getBoundingClientRect().y + mainEl.offsetHeight < 150) {
        if (!document.querySelector('.header__logo__outline').classList.contains('light')) {
          document.querySelector('.header__logo__outline').classList.add('light');
        }
        if (document.querySelector('.header__logo__outline').classList.contains('dark')) {
          document.querySelector('.header__logo__outline').classList.remove('dark');
        }

        if (!navEl.classList.contains('light')) {
          navEl.classList.add('light');
        }
        if (navEl.classList.contains('dark')) {
          navEl.classList.remove('dark');
        }
      }
    })
  }
});

document.addEventListener( 'click', (e) => {
  if (e.target.classList.contains( 'hamburger__wrapper' )
  || e.target.classList.contains( 'hamburger__text' )) {    
    e.preventDefault();
    if (hamburger.classList.contains('hamburger__open')) {
      hamburger.classList.remove('hamburger__open');
      navigation.classList.remove('navigation__open');
      body.classList.remove('modal-open');
      body.style.overflow = 'unset';
      body.style.paddingRight = 'inherit';
    } else {
      body.classList.add('modal-open');
      body.style.paddingRight = '0px';
      body.style.overflow = 'hidden';
      navigation.classList.add('navigation__open');
      hamburger.classList.add('hamburger__open');
    }
  } 
});
