/**
 * Helpers
 */
import { onVisibilityChange } from './helpers'
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

/**
 * Web standards
 */
import './lazyload'
import './cookieconsent'
import './slider'
import './trustedby'
import './animations'
import './navigation'
import './monitor'

/**
 * Scripts which doesn't need dynamic import
 */
import {
  Modal,
  Collapse
} from 'bootstrap';

/**
 * Dynamic import scripts
 */
const elements = [
  '.services',
  '.payoff',
  '.hero-image',
  '.slider-container'
];

[].forEach.call(elements, (element) => {


  if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {

    const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

      if (element === '.services') {
        const Swiper = import('./services-cards')
      }

      if (element === '.payoff') {
        const Swiper = import('./payoff')
      }

      if (element === '.hero-image') {
        const Swiper = import('./curtains')
      }

      // if (element === '.slider-container') {
      //   const Swiper = import('./slider')
      // }

      // Example library with init:
      // if (element === '[data-aos]' || element === '.aos-element') {
      //   const AOS = import('aos').then(AOS => {
      //     AOS.init();
      //   });
      // }
    })

    if (window.addEventListener) {
      addEventListener('DOMContentLoaded', lazyloadHandler, false);
      addEventListener('load', lazyloadHandler, false);
      addEventListener('scroll', lazyloadHandler, false);
      addEventListener('resize', lazyloadHandler, false);
    } else if (window.attachEvent) {
      attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
      attachEvent('onload', lazyloadHandler);
      attachEvent('onscroll', lazyloadHandler);
      attachEvent('onresize', lazyloadHandler);
    }
  }
});

let inschrijvenBtns = document.querySelectorAll('.inschrijven-click');
let trainingSelect = document.querySelector('select[name="training-naam"]');
let eigenBijdrageSelect = document.querySelector('input[name="accepteert-eigen-bijdrage[]"]');
let groupEigenBijdrage = document.querySelector('div[data-id="eigen-bijdrage-check-group"]');
[...inschrijvenBtns].forEach((btn) => {
  btn.addEventListener('click', (e) => {
    if (trainingSelect) {
      trainingSelect.value = e.target.dataset.trainingName
      if (groupEigenBijdrage.classList.contains('wpcf7cf-hidden') || groupEigenBijdrage.style.display === 'block') {
        if (eigenBijdrageSelect.checked) {
          eigenBijdrageSelect.checked = false;
        } else {
          eigenBijdrageSelect.checked = true;
        }
      } else {
        eigenBijdrageSelect.checked = true;
      }
      if (groupEigenBijdrage.classList.contains('wpcf7cf-hidden')) {
        eigenBijdrageSelect.checked = true;
      }
    }

    setTimeout(() => {
      var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      if(viewportWidth > 991) {
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: window.outerHeight + document.querySelector(e.target.hash).offsetTop - 150
        });
      } else {
        window.scrollTo(0, document.querySelector(e.target.hash).offsetTop + document.querySelector(e.target.hash).offsetHeight - 430);
      }
    }, 300)
  })
})