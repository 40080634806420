import LazyLoad from "vanilla-lazyload";


const images = document.querySelectorAll("img, iframe, .lazy, video, source, picture");

const createLazyLoadInstance = () => {
  return new LazyLoad({
      unobserve_entered: true,
      webp: true,
      elements_selector: ".lazy"
    },
    images
  );
};

const delayedCreateLazyLoadInstance = () => {
  setTimeout(createLazyLoadInstance, 100);
}

document.addEventListener("DOMContentLoaded", createLazyLoadInstance);
