import Swiper from 'swiper';
import { Mousewheel } from 'swiper'

Swiper.use( Mousewheel );

const sliders = document.querySelectorAll('.block-slider');
  if (sliders) {
    sliders.forEach( (slider) => {


      let identifier = `.swiper-${slider.dataset.sliderId}`;
      let options =  {
        slidesPerView: 1.3,
        spaceBetween: 70,
        freemode: false,
        grabCursor: true,
        mousewheel: {
          releaseOnEdges: true,
        },
        breakpoints: {
          567: {
            slidesPerView: slider.dataset.sliderHsettings === 'width' ? 1.3 : 4.3,
          }
        }
      };
      let newSlider = new Swiper(identifier, options );

      newSlider.init();

    })
  }